<template>
  <Layout>
    <div class="position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg profile-setting-img">
        <img src="@/assets/images/profile-bg.jpg" class="profile-wid-img" alt="" />
        <div class="overlay-content">
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-3">
        <div class="card mt-n5">
          <div class="card-body p-4">
            <div class="text-center">
              <div class="
                  profile-user
                  position-relative
                  d-inline-block
                  mx-auto
                  mb-4
                ">
                <img src="@/assets/images/avatar.png" class="
                    rounded-circle
                    avatar-xl
                    img-thumbnail
                    user-profile-image
                  " alt="user-profile-image" />
                <div class="avatar-xs p-0 rounded-circle profile-photo-edit">
                  <label class="profile-photo-edit avatar-xs">
                    <span class="avatar-title rounded-circle bg-light text-body">
                      <i class="ri-refresh-line"></i>
                    </span>
                  </label>
                </div>
              </div>
              <h5 class="fs-16 mb-1">{{ currentUser.fullname }}</h5>
              <p class="text-muted mb-0">{{ currentUserRoles }}</p>
            </div>
          </div>
        </div>
        <!--end card-->
      </div>
      <!--end col-->
      <div class="col-xxl-9">
        <div class="card mt-xxl-n5">
          <div class="card-header">
            <ul class="
                nav nav-tabs-custom
                rounded
                card-header-tabs
                border-bottom-0
              " role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="tab" href="#userDetails" role="tab">
                  <i class="fas fa-home"></i>
                  User Details
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                  <i class="far fa-user"></i>
                  Change Password
                </a>
              </li>
            </ul>
          </div>
          <div class="card-body p-4">
            <div class="tab-content">
              <div class="tab-pane active" id="userDetails" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="fullname" class="form-label">Full Name</label>
                        <input v-model="currentUser.fullname" type="text" class="form-control" id="fullname" placeholder="Enter your full name" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="username" class="form-label">User Name</label>
                        <input v-model="currentUser.username" type="text" class="form-control" id="username" placeholder="Enter your username" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="walletAddress" class="form-label">Wallet Address</label>
                        <input v-model="currentUser.walletAddress" type="text" class="form-control" id="walletAddress" placeholder="Wallet address" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="hstack gap-2 justify-content-end">
                        <button type="submit" class="btn btn-primary" @click="updatedata">
                          Updates
                        </button>
                        <button type="button" class="btn btn-soft-success">
                          Cancel
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
              <div class="tab-pane" id="changePassword" role="tabpanel">
                <form action="javascript:void(0);">
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <div>
                        <label for="oldpasswordInput" class="form-label">Old Password*</label>
                        <input type="password" class="form-control" id="oldpasswordInput"
                          placeholder="Enter current password" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="newpasswordInput" class="form-label">New Password*</label>
                        <input type="password" class="form-control" id="newpasswordInput"
                          placeholder="Enter new password" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-4">
                      <div>
                        <label for="confirmpasswordInput" class="form-label">Confirm Password*</label>
                        <input type="password" class="form-control" id="confirmpasswordInput"
                          placeholder="Confirm password" />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="text-end">
                        <button type="submit" class="btn btn-success" @click="changepass">
                          Change Password
                        </button>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
              </div>
              <!--end tab-pane-->
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>

<script>
  import axios from 'axios';
  import Layout from "@/layouts/main.vue";
  import appConfig from "@/../app.config";
  import store from '@/state/store'

  export default {
    page: {
      title: "Setting",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        title: "Setting",
        breadcrumbs: [],
        value: ['javascript'],
        date: null,
      };
    },
    components: {
      Layout
    },
    computed: {
      currentUser() {
        return store.getters['auth/currentUser']
      },
      currentUserRoles() {
        const roles = []
        store.getters['auth/currentUser'].roles.forEach(r => {
          roles.push(r)
        })
        return roles.join(', ')
      }
    },
    methods: {
      changepass() {
        var data = {
          password: document.getElementById('oldpasswordInput').value,
          new_password: document.getElementById('newpasswordInput').value,
          confirm_password: document.getElementById('confirmpasswordInput').value
        }
        axios.patch(process.env.VUE_APP_API_URL + '/updatepassword', data).then((data) => {
          console.log(data);
        }).catch((e) => {
          console.log(e)
        })
      },
      updatedata() {
        var userid = localStorage.getItem('userid');
        var data = {
          first_name: document.getElementById('firstnameInput').value,
          last_name: document.getElementById('lastnameInput').value,
          phone: document.getElementById('phonenumberInput').value,
          email: document.getElementById('emailInput').value,
          joining_date: document.getElementById('dateinput').value,
          skills: document.getElementById('skillsinput').value,
          designation: document.getElementById('designationInput').value,
          website: document.getElementById('websiteInput1').value,
          city: document.getElementById('cityInput').value,
          country: document.getElementById('countryInput').value,
          zipcode: document.getElementById('zipcodeInput').value,
          Description: document.getElementById('exampleFormControlTextarea').value,
        }
        axios.patch(process.env.VUE_APP_API_URL + '/user/' + userid, data).then((data) => {
          console.log(data);
        }).catch((e) => {
          console.log(e)
        })
      }
    },
  };
</script>
